import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />

    <div className="contactInfo">
      <h1>Gosia Zboina</h1>
      <h2>zutizuterful@gmail.com</h2>
      <h3>+48 603 884 830</h3>
    </div>
  </Layout>
)

export default ContactPage
